<template src="./home.html"></template>

<script>
import QrcodeVue from 'qrcode.vue';
import SocketIO from 'socket.io-client';

const Config = require('~/config/default.env').default;

const QR_CODE_LIFE_TIME = 10 * 60 * 1000;
// const GET_TOKEN_INTERVAL = 5 * 1000;
const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'GondolaMiniHome',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      quotes: null,
      qrGenTimer: null,
      getTokenTimer: null,
    };
  },
  computed: {
    QRcode() {
      return this.$store.state.QRcode.value || '';
    },
  },
  created() {
    Cookies.set('token', '', { expires: 0 });
    this.$store.dispatch('generateQrCode');

    this.qrGenTimer = setInterval(() => {
      if (this.$store.state.QRcode.isLoggedIn) {
        return clearInterval(this.qrGenTimer);
      }
      this.$store.dispatch('generateQrCode');
    }, QR_CODE_LIFE_TIME);

    const self = this;
    socket.on('qr_code_login', function (data) {
      if (self.$store.state.QRcode.value == data.qr_code) {
        Cookies.set('token', data.token, { expires: 1 });
        self.$store.commit('authToken', data.token);
        self.$store.dispatch('getToken', { redirect: '/gondola-mini/signed-in', data: self });
        socket.off('qr_code_login');
      }
    });
  },
  destroyed() {
    clearInterval(this.qrGenTimer);
  },
};
</script>
